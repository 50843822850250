//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import dates from '@/mixinsjs/dates';

export default {
    mixins: [dates],
    props: {
        tabs: {
            type: Array,
        },
        processToken: {
            type: String,
        },
        noMargin: {
            type: Boolean,
        },
        hasAccess: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        userInfo() {
            if (this.$store.state.auth !== null) {
                return this.$store.state.auth;
            }
            return null;
        },
    },
    methods: {
        ...mapActions({ setSnackBar: 'setSnackBar' }),
        daysRemain(expiresAt) {
            return this.mxDaysRemain(expiresAt);
        },
        expireDate(expiresAt) {
            return this.$moment(expiresAt).locale('en').format('ll');
        },
        tabIsDone(tab) {
            if (!tab.infopackTabOwners) return;
            if (!Array.isArray(tab.infopackTabOwners)) {
                return tab.infopackTabOwners.statusRef === this.$Enums.ProcessTaskStatus.DONE;
            }
            return tab.infopackTabOwners.some(
                ({ userId, statusRef }) =>
                    userId == this.userInfo.user.id &&
                    statusRef == this.$Enums.ProcessTaskStatus.DONE
            );
        },
        stepClickHandler(tab) {
            if (!this.hasAccess && tab.premium) {
                this.setSnackBar({
                    toggle: true,
                    color: 'red',
                    message: this.$t('info.messages.shouldBeRegisteredAtX').replace(
                        'PACK',
                        this.$t('info.public.process')
                    ),
                });
            }
        },
    },
};
