import dates from '@/mixinsjs/dates';
import commafy from '@/mixinsjs/commafy';

export default {
    mixins: [dates, commafy],
    methods: {
        mxPrice(pack) {
            let price;
            if (pack.myPermission?.canManage) {
                price = pack.subscriptions?.[0]?.price;
            } else {
                price = pack.myPermission?.price;
            }
            return price
                ? `${this.commafy(price / 10)} ${this.$t('info.currency.toman')}`
                : this.$t('info.currency.free');
        },
        mxHasAvatar(pack) {
            return pack.owner?.avatars?.length;
        },
        mxTargetUrl(pack) {
            return `/${pack.type === this.$Enums.PackType.PROCESS ? 'process' : 'infopacks'}/${
                pack.parentId ? '' : 'private-'
            }${pack.token}`;
        },
        mxIsParticipant(pack) {
            return pack.myPermission?.canParticipate;
        },
        mxIsAuthor(pack) {
            return pack.myPermission?.canManage;
        },
        mxStorage() {
            return process.env.VUE_APP_API_STORAGE_URL_ONLY;
        },
        mxPublishedDate(pack) {
            const targetDate = pack.publishedAt || pack.createdAt;
            return this.$jalaliMoment(targetDate ? new Date(targetDate) : new Date())
                .locale('fa')
                .format('DD MMMM YYYY');
        },
    },
};
