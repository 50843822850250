//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        openDrawer: {
            type: Boolean,
        },
    },
    data() {
        return {
            showDrawer: this.openDrawer,
            fullscreen: false,
        };
    },
    watch: {
        openDrawer(val) {
            this.showDrawer = val;
        },
        showDrawer(val) {
            if (!val) {
                this.removeFullScreen();
                this.$emit('close-drawer');
            }
        },
    },
    methods: {
        removeFullScreen() {
            this.fullscreen = false;
        },
        addFullScreen() {
            this.fullscreen = true;
        },
        toggleTimelineFullScreen() {
            this.fullscreen = !this.fullscreen;
        },
        toggleTimeline() {
            this.showDrawer = !this.showDrawer;
            if (!this.showDrawer) {
                this.removeFullScreen();
            }
        },
        closeBottomDrawer() {
            this.showDrawer = false;
            this.$emit('close-drawer');
        },
        swipeHandler(evt) {
            if (evt === 'bottom') {
                if (this.fullscreen) {
                    this.removeFullScreen();
                } else {
                    this.closeBottomDrawer();
                }
            } else if (evt === 'top') {
                this.addFullScreen();
            }
        },
    },
};
