var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"process-steps"},[_c('ul',{staticClass:"pl-0 pr-4",class:{ 'mt-0': _vm.noMargin }},_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.id,on:{"click":function($event){return _vm.$emit('close-list')}}},[_c('nuxt-link',{staticClass:"d-flex",class:{ done: _vm.tabIsDone(tab) },attrs:{"to":_vm.hasAccess || !tab.premium
                        ? _vm.localePath(
                              ("/process/" + _vm.processToken + "/" + (tab.token) + "/" + (encodeURIComponent(
                                  tab.slug
                              )))
                          )
                        : ''},nativeOn:{"click":function($event){return _vm.stepClickHandler(tab)}}},[_c('div',{staticClass:"process-steps__level d-flex flex-column justify-center g-2",class:{
                        done: _vm.tabIsDone(tab),
                        active: _vm.$route.params.processSlugOrTabToken == tab.token,
                    }},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.$breakpoint.mdAndUp ||
                                !tab ||
                                !tab.title ||
                                tab.title.length < 25,"max-width":"500px","color":"rgba(0, 0, 0, 0.87)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"level-title text-clamp-1",class:{ done: _vm.tabIsDone(tab) }},'p',attrs,false),on),[_vm._v("\n                                    "+_vm._s(tab.title || _vm.$t('info.public.untitled'))+"\n                                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(tab.title))])]),_vm._v(" "),(!_vm.hasAccess && !tab.premium)?_c('v-chip',{staticClass:"free-badge ms-2",attrs:{"small":""}},[_vm._v("\n                            "+_vm._s(_vm.$t('info.currency.free'))+"\n                        ")]):_vm._e()],1),_vm._v(" "),_c('v-expand-transition',[(!_vm.tabIsDone(tab) && tab.expiresAt)?_c('div',{staticClass:"level-info"},[_c('div',{staticClass:"calendar"},[_c('v-icon',[_vm._v(" mdi-calendar ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.expireDate(tab.expiresAt)))])],1),_vm._v(" "),_c('div',{staticClass:"persian-digit",class:{
                                    expired: _vm.daysRemain(tab.expiresAt) < 1,
                                    nearToDeath:
                                        _vm.daysRemain(tab.expiresAt) > 0 &&
                                        _vm.daysRemain(tab.expiresAt) < 8,
                                }},[_c('v-icon',[_vm._v(" mdi-update ")]),_vm._v(" "),_c('span',[_vm._v("\n                                    "+_vm._s(Math.abs(_vm.daysRemain(tab.expiresAt)))+"\n                                    "+_vm._s(Math.abs(_vm.daysRemain(tab.expiresAt)) > 1
                                            ? _vm.$t('process.dates.days')
                                            : _vm.$t('process.dates.day'))+"\n                                    "+_vm._s(_vm.daysRemain(tab.expiresAt) > 0
                                            ? _vm.$t('process.dates.remains')
                                            : _vm.$t('process.dates.passed'))+"\n                                ")])],1)]):_vm._e()]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$breakpoint.mdAndUp),expression:"!$breakpoint.mdAndUp"}],staticClass:"level-icon"},[_c('v-icon',{staticClass:"iconiz-chevron-left"})],1)],1)])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }