import { render, staticRenderFns } from "./item.vue?vue&type=template&id=50a7c6fe&"
import script from "./item.vue?vue&type=script&lang=ts&"
export * from "./item.vue?vue&type=script&lang=ts&"
import style0 from "./item.vue?vue&type=style&index=0&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IProgressCircular: require('/app/components/i/progress/circular.vue').default,ProcessStepsList: require('/app/components/process/steps-list/index.vue').default,IBottomDrawer: require('/app/components/i/bottom-drawer/index.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VCard,VCardText,VChip,VDivider,VFadeTransition,VHover,VIcon,VImg,VList,VListItem,VMenu,VTab,VTabs,VTooltip})
