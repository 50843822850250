export default {
    methods: {
        pickHex(color1: number[], color2: number[], weight: number): number[] {
            const w1 = weight;
            const w2 = 1 - w1;
            const rgb = [
                Math.round(color1[0] * w1 + color2[0] * w2),
                Math.round(color1[1] * w1 + color2[1] * w2),
                Math.round(color1[2] * w1 + color2[2] * w2),
            ];
            return rgb;
        },
    },
};
