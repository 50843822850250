export default {
    methods: {
        /**
         * @description converts rgba to hex
         * source https://gist.github.com/whitlockjc/9363016
         * @param rgba
         * @returns string
         */
        rgbaToHex(rgba: number[]): string {
            const r = rgba[0];
            const g = rgba[1];
            const b = rgba[2];
            const a = rgba[3];

            const outParts = [
                r.toString(16),
                g.toString(16),
                b.toString(16),
                a
                    ? Math.round(a * 255)
                          .toString(16)
                          .substring(0, 2)
                    : null,
            ];

            // Pad single-digit output values
            outParts.forEach((part, i) => {
                if (part && part.length === 1) {
                    outParts[i] = '0' + part;
                }
            });

            return '#' + outParts.join('');
        },
    },
};
