import { mapGetters } from 'vuex';
import moment from 'moment';
import jalaliMoment from 'jalali-moment';

export default {
    computed: {
        ...mapGetters({ mxServerDate: 'serverDate' }),
    },
    methods: {
        mxYears(startYear) {
            try {
                const years = [];
                if (this.$i18n.locale === 'en') {
                    const currentYear = new Date().getFullYear();
                    let startYear = 1900;
                    while (startYear <= currentYear) {
                        years.push(startYear++);
                        // years.reverse()
                    }
                } else {
                    const c = new Date().toString();
                    const year = jalaliMoment(c).locale('fa').format('YYYY');
                    const currentYear = year;
                    startYear = 1300;
                    while (startYear <= currentYear) {
                        years.push(startYear++);
                    }
                }
                years.reverse();
                return years;
            } catch (e) {
                // TODO handle error
            }
        },

        mxExtractYear(date: string): string {
            try {
                if (this.$i18n.locale === 'en') {
                    return moment(new Date(date)).format('YYYY');
                } else {
                    return jalaliMoment(new Date(date)).locale('fa').format('jYYYY');
                }
            } catch (error) {
                return '';
            }
        },

        mxDaysRemain(expiresAt) {
            const currentDate = this.mxServerDate ? moment(this.mxServerDate) : moment(new Date());
            const expireDate = moment(expiresAt);

            return expireDate.diff(currentDate, 'days');
        },

        mxDateDifferenceFromNow(date) {
            const translate = this.$i18n.messages[this.$i18n.locale];
            const now = this.mxServerDate ? moment(this.mxServerDate) : moment();
            const createDate = date;

            const {
                minutes,
                minute,
                ago,
                present,
                hour,
                hours,
                day,
                days,
                weeks,
                week,
                month,
                months,
                year,
                years,
            } = translate.info.dateTime;

            if (now.diff(createDate, 'minutes') <= 0) {
                return present;
            } else if (now.diff(createDate, 'minutes') < 60) {
                if (now.diff(createDate, 'minutes') > 1) {
                    return now.diff(createDate, 'minutes') + ' ' + minutes + ' ' + ago;
                } else {
                    return now.diff(createDate, 'minutes') + ' ' + minute + ' ' + ago;
                }
            } else if (now.diff(createDate, 'hours') < 24) {
                if (now.diff(createDate, 'hours') > 1) {
                    return now.diff(createDate, 'hours') + ' ' + hours + ' ' + ago;
                } else {
                    return now.diff(createDate, 'hours') + ' ' + hour + ' ' + ago;
                }
            } else if (now.diff(createDate, 'days') < 24) {
                if (now.diff(createDate, 'days') > 1) {
                    return now.diff(createDate, 'days') + ' ' + days + ' ' + ago;
                } else {
                    return now.diff(createDate, 'days') + ' ' + day + ' ' + ago;
                }
            } else if (now.diff(createDate, 'weeks') < 4) {
                if (now.diff(createDate, 'weeks') > 1) {
                    return now.diff(createDate, 'weeks') + ' ' + weeks + ' ' + ago;
                } else {
                    return now.diff(createDate, 'weeks') + ' ' + week + ' ' + ago;
                }
            } else if (now.diff(createDate, 'months') < 12) {
                if (now.diff(createDate, 'months') > 1) {
                    return now.diff(createDate, 'months') + ' ' + months + ' ' + ago;
                } else {
                    return now.diff(createDate, 'months') + ' ' + month + ' ' + ago;
                }
            } else if (now.diff(createDate, 'years') > 1) {
                return now.diff(createDate, 'years') + ' ' + years + ' ' + ago;
            } else {
                return now.diff(createDate, 'years') + ' ' + year + ' ' + ago;
            }
        },
    },
};
