//
//
//
//
//
//

import pickHex from '@/mixinsjs/pickHex';
import rgbaToHex from '@/mixinsjs/rgbaToHex';

export default {
    mixins: [pickHex, rgbaToHex],
    props: {
        colorRange: {
            validator(value) {
                if (!value.start || !value.end) return false;
                if (!Array.isArray(value.start) || !Array.isArray(value.end)) return false;
                if (value.start.length !== 3 || value.end.length !== 3) return false;
                value.start.concat(value.end).forEach((itm) => {
                    if (typeof itm !== 'number') return false;
                });
                return true;
            },
            default: () => ({
                start: [76, 176, 79],
                end: [230, 129, 46],
            }),
        },
        value: {
            type: Number,
            default: 0,
        },
        rotate: {
            type: Number,
            default: 270,
        },
        size: {
            type: Number,
            default: 75,
        },
        width: {
            type: Number,
            default: 9,
        },
    },
    computed: {
        color() {
            return this.rgbaToHex(
                this.pickHex(this.colorRange.start, this.colorRange.end, this.value / 100)
            );
        },
    },
};
